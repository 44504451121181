import { createSlice } from "@reduxjs/toolkit";
import { userDelete, userLogin, userEdit, getUserById } from "./authActions";

const initialState = {
  loading: false,
  userInfo: null,
  error: null,
  success: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: {
    [userLogin.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [userLogin.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.userInfo = {
        user_id: payload?.user?._id,
        user_name: payload?.user?.firstname,
        user_email: payload?.user?.email,
        user_role: payload?.user?.role,
        firstVisit: payload?.user?.firstVisit,
      };
    },
    [userLogin.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [userDelete.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [userDelete.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.userInfo = {
        user_id: payload?.user?.id,
        user_name: payload?.user?.firstname,
        user_email: payload?.user?.email,
        firstVisit: payload?.user?.firstVisit,
      };
    },
    [userDelete.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [userEdit.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [userEdit.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.userInfo = {
        user_id: payload?.user?._id,
        user_name: payload?.user?.fullname,
        user_email: payload?.user?.email,
      };
    },
    [userEdit.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [getUserById.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getUserById.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.userInfo = {
        user_id: payload?.user?._id,
        user_name: payload?.user?.firstname,
        user_email: payload?.user?.email,
      };
    },
    [getUserById.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export default authSlice.reducer;
