import { TextField } from "@mui/material";

const TextInput = ({ type = "text", input, label, meta, InputProps }) => {
  return (
    <>
      <TextField
        fullWidth
        type={type}
        name={input.name}
        value={input.value}
        onChange={input.onChange}
        label={label}
        error={meta.error && meta.touched}
        InputProps={InputProps}
      />
      {meta.error && meta.touched && (
        <span
          style={{
            fontSize: 12,
            fontWeight: "600",
            marginTop: 2,
            color: "#ff4843",
          }}
        >
          {meta.error}
        </span>
      )}
    </>
  );
};

export default TextInput;
