import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { baseURL } from "src/constants/baseURL";

const token = localStorage.getItem("userToken");

export const createProduct = createAsyncThunk(
  "products/create",
  async (data, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      await axios.post(`${baseURL}/product`, data, config);
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const getProductsForBrand = createAsyncThunk(
  "products/get",
  async ({ id }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const { data } = await axios.get(`${baseURL}/product/${id}`, config);
      if (data) {
        return data;
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const getAllProducts = createAsyncThunk(
  "products/getAll",
  async ({}, { rejectWithValue }) => {
    // ("Product Action", data, new Date());
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const { data } = await axios.get(`${baseURL}/product`, config);
      if (data) {
        return data;
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const editProduct = createAsyncThunk(
  "product/edit",
  async ({ id, values }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.put(`${baseURL}/product/${id}`, values, config);

      if (res?.data) {
        return res.data;
      }
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error?.response?.data?.error?.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteProduct = createAsyncThunk(
  "product/delete",
  async (id, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const { data } = await axios.delete(`${baseURL}/product/${id}`, config);

      if (data) {
        // ("Product delete payload", data);
        return data;
      }
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error?.response?.data?.error?.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const sortProducts = createAsyncThunk(
  "product/sort",
  async ({ sortBy, sort }, { rejectWithValue }) => {
    try {
      let url = baseURL + "/product/filter";

      if (sortBy) {
        url = baseURL + `/product/filter?sortBy=${sortBy}&sort=${sort}`;
      }
      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const { data } = await axios.get(`${url}`, config);

      if (data) {
        return data;
      }
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error?.response?.data?.error?.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
