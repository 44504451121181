import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { baseURL } from "src/constants/baseURL";

const token = localStorage.getItem("userToken");

export const userLogin = createAsyncThunk(
  "auth/login",
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data, status } = await axios.post(
        `${baseURL}/user/login`,
        { email, password },
        config
      );

      if (data && status === 200) {
        localStorage.setItem("userToken", data?.token);
        localStorage.setItem("userRole", data?.user?.role);
        localStorage.setItem("userId", data?.user?._id);
        localStorage.setItem(
          "userName",
          `${data?.user?.firstname} ${data?.user?.lastname}`
        );
        localStorage.setItem("userEmail", data?.user?.company_email);
        localStorage.setItem("jobTitle", data?.user?.jobTitle);
        return data;
      }
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const userDelete = createAsyncThunk(
  "user/delete",
  async (id, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const { data } = await axios.delete(`${baseURL}/user/${id}`, config);

      if (data) {
        return data;
      }
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error?.response?.data?.error?.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const userEdit = createAsyncThunk(
  "user/edit",
  async ({ id, values }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.put(`${baseURL}/user/${id}`, values, config);

      if (res?.data) {
        return res.data;
      }
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error?.response?.data?.error?.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getUserById = createAsyncThunk(
  "user/getById",
  async ({ id }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.get(`${baseURL}/user/${id}`, config);

      if (res?.data) {
        return res.data;
      }
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error?.response?.data?.error?.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
