import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { baseURL, token } from "src/constants/baseURL";

export const createUser = createAsyncThunk(
  "user/create",
  async (data, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      await axios.post(`${baseURL}/user/create`, data, config);
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const deleteUser = createAsyncThunk(
  "user/delete",
  async (id, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const { data } = await axios.delete(`${baseURL}/user/${id}`, config);

      if (data) {
        return data;
      }
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error?.response?.data?.error?.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const editUser = createAsyncThunk(
  "user/edit",
  async ({ id, values }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.put(`${baseURL}/user/${id}`, values, config);

      if (res?.data) {
        return res.data;
      }
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error?.response?.data?.error?.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const changePassword = createAsyncThunk(
  "user/edit",
  async ({ email, newPassword }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };
      const res = await axios.patch(
        `${baseURL}/user/change-password`,
        { email, newPassword },
        config
      );

      if (res?.data) {
        return res.data;
      }
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error?.response?.data?.error?.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
