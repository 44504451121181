import { useSelector } from "react-redux";
import SvgColor from "../../../components/svg-color";

const icon = (name) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const getNavConfig = (role) => {
  const jobTitle = localStorage.getItem("jobTitle"); // Move inside the function

  if (role === "admin") {
    return [
      {
        title: "dashboard",
        path: "/dashboard/app",
        icon: icon("ic_analytics"),
      },
      {
        title: "users",
        path: `/dashboard/admin/users`,
        icon: icon("ic_customer"),
      },
      {
        title: "suppliers",
        path: `/dashboard/admin/suppliers`,
        icon: icon("ic_delivery"),
      },
      {
        title: "customers",
        path: `/dashboard/admin/customers`,
        icon: icon("ic_customer"),
      },
      {
        title: "products",
        path: `/dashboard/admin/products`,
        icon: icon("ic_product"),
      },
      {
        title: "orders",
        path: `/dashboard/admin/orders`,
        icon: icon("ic_cart"),
      },
      {
        title: "sales",
        path: `/dashboard/admin/sales`,
        icon: icon("ic_cart"),
      },
    ];
  } else if (role === "user" && jobTitle === "Delivery Person") {
    return [
      {
        title: "order to deliver",
        path: `/dashboard/user/dp-orders`,
        icon: icon("ic_cart"),
      },
    ];
  } else if (role === "user" && jobTitle !== "Delivery Person") {
    return [
      {
        title: "dashboard",
        path: "/dashboard/app",
        icon: icon("ic_analytics"),
      },
      {
        title: "customer",
        path: `/dashboard/user/customers`,
        icon: icon("ic_customer"),
      },
      {
        title: "products",
        path: `/dashboard/user/products`,
        icon: icon("ic_product"),
      },
      {
        title: "orders",
        path: `/dashboard/user/orders`,
        icon: icon("ic_cart"),
      },
      {
        title: "sales",
        path: `/dashboard/user/sales`,
        icon: icon("ic_cart"),
      },
    ];
  }
};

const NavConfig = () => {
  const userRole = localStorage.getItem("userRole"); // Retrieve role
  return getNavConfig(userRole); // Pass role to getNavConfig
};

export default NavConfig;
