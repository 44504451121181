import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  changePassword,
  createUser,
  deleteUser,
  editUser,
} from "./userActions";

const initialState = {
  loading: false,
  userData: null,
  userDeleted: false,
  error: null,
  success: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: {
    [createUser.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [createUser.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.userData = { payload };
    },
    [createUser.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [editUser.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [editUser.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.userData = { payload };
    },
    [editUser.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [deleteUser.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [deleteUser.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.userData = { payload };
    },
    [deleteUser.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [changePassword.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [changePassword.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.userData = { payload };
    },
    [changePassword.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export default userSlice.reducer;
