import { lazy, Suspense } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import DashboardLayout from "./layouts/dashboard";
import SimpleLayout from "./layouts/simple";
import LoginPage from "./pages/LoginPage";
import Page404 from "./pages/Page404";
import { useSelector } from "react-redux";

const LoadSuspense = (Component) => (props) =>
  (
    <Suspense fallback={<h1>Loading...</h1>}>
      <Component {...props} />
    </Suspense>
  );

const DashboardAppPage = LoadSuspense(
  lazy(() => import("./pages/DashboardAppPage"))
);

// Admin Routes
const AdminOrdersListPage = LoadSuspense(
  lazy(() => import("./pages/Orders/AdminOrdersList"))
);
const AdminSalesListPage = LoadSuspense(
  lazy(() => import("./pages/Sales/AdminSalesList"))
);
const AdminCustomersListPage = LoadSuspense(
  lazy(() => import("./pages/Customer/AdminCustomersList"))
);

// User
const UserPage = LoadSuspense(lazy(() => import("./pages/UserPage")));
const UsersList = LoadSuspense(lazy(() => import("./pages/Users/UsersList")));
const CreateUser = LoadSuspense(lazy(() => import("./pages/Users/CreateUser")));
const EditUser = LoadSuspense(lazy(() => import("./pages/Users/EditUser")));

// Supplier
const SupplierPage = LoadSuspense(lazy(() => import("./pages/SupplierPage")));
const SuppliersList = LoadSuspense(
  lazy(() => import("./pages/Suppliers/SuppliersList"))
);
const CreateSupplier = LoadSuspense(
  lazy(() => import("./pages/Suppliers/CreateSupplier"))
);
const EditSupplier = LoadSuspense(
  lazy(() => import("./pages/Suppliers/EditSupplier"))
);
const CreateCustomer = LoadSuspense(
  lazy(() => import("./pages/Customer/CreateCustomer"))
);
const EditCustomer = LoadSuspense(
  lazy(() => import("./pages/Customer/EditCustomer"))
);
const CustomerList = LoadSuspense(
  lazy(() => import("./pages/Customer/CustomerList"))
);
const EditProfile = LoadSuspense(
  lazy(() => import("./pages/Users/EditProfile"))
);

//Orders
const CreateOrder = LoadSuspense(
  lazy(() => import("./pages/Orders/CreateOrder"))
);
const EditOrder = LoadSuspense(lazy(() => import("./pages/Orders/EditOrder")));
const OrdersList = LoadSuspense(
  lazy(() => import("./pages/Orders/OrdersList"))
);

//Sales
const CreateSale = LoadSuspense(lazy(() => import("./pages/Sales/CreateSale")));
const EditSale = LoadSuspense(lazy(() => import("./pages/Sales/EditSale")));
const SalesList = LoadSuspense(lazy(() => import("./pages/Sales/SalesList")));

// Product
const ProductsPage = LoadSuspense(lazy(() => import("./pages/ProductsPage")));
const AddProducts = LoadSuspense(
  lazy(() => import("./pages/Products/AddProducts"))
);
const EditProduct = LoadSuspense(
  lazy(() => import("./pages/Products/EditProduct"))
);
const ProductDetails = LoadSuspense(
  lazy(() => import("./pages/Products/ProductDetails"))
);
const ChangePasswordPage = LoadSuspense(
  lazy(() => import("./pages/ChangePasswordPage"))
);
const DPOrdersList = LoadSuspense(
  lazy(() => import("./pages/Orders/DPOrdersList"))
);
const DPEditOrder = LoadSuspense(
  lazy(() => import("./pages/Orders/DPEditOrders"))
);

export default function Router() {
  const { userInfo } = useSelector((state) => state.auth);
  const isLogged = localStorage.getItem("userToken");
  const userRole = localStorage.getItem("userRole");

  const adminRoutes = [
    { path: "admin/user", element: <UserPage /> },
    { path: "admin/users", element: <UsersList /> },
    { path: "admin/user/create", element: <CreateUser /> },
    { path: "admin/user/edit/:id", element: <EditUser /> },
    { path: "admin/supplier", element: <SupplierPage /> },
    { path: "admin/suppliers", element: <SuppliersList /> },
    { path: "admin/supplier/create", element: <CreateSupplier /> },
    { path: "admin/supplier/edit/:id", element: <EditSupplier /> },
    { path: "admin/sales", element: <AdminSalesListPage /> },
    { path: "admin/sale/edit/:id", element: <EditOrder /> },
    { path: "admin/orders", element: <AdminOrdersListPage /> },
    { path: "admin/order/create", element: <CreateOrder /> },
    { path: "admin/order/edit/:id", element: <EditOrder /> },
    { path: "admin/customers", element: <AdminCustomersListPage /> },
    { path: "admin/customer/create", element: <CreateCustomer /> },
    { path: "admin/customer/edit/:id", element: <EditCustomer /> },
    { path: "admin/products", element: <ProductsPage /> },
    { path: "admin/products/create", element: <AddProducts /> },
    { path: "admin/product/edit", element: <EditProduct /> },
    { path: "admin/products/preview", element: <ProductDetails /> },
  ];

  const userRoutes = [
    { path: "user/customer/create", element: <CreateCustomer /> },
    { path: "user/customer/edit/:id", element: <EditCustomer /> },
    { path: "user/customers", element: <CustomerList /> },
    { path: "user/order/create", element: <CreateOrder /> },
    { path: "user/order/edit/:id", element: <EditOrder /> },
    { path: "user/orders", element: <OrdersList /> },
    { path: "user/dp-order/:id", element: <DPEditOrder /> },
    { path: "user/dp-orders", element: <DPOrdersList /> },

    // { path: "user/sale/create", element: <CreateSale /> },
    { path: "user/sale/edit/:id", element: <EditOrder /> },
    { path: "user/sales", element: <SalesList /> },
    { path: "user/profile", element: <EditProfile /> },
    { path: "user/products", element: <ProductsPage /> },
    { path: "user/products/create", element: <AddProducts /> },
    { path: "user/product/edit", element: <EditProduct /> },
    { path: "user/products/preview", element: <ProductDetails /> },
  ];

  const routes = useRoutes([
    {
      path: "/",
      element: (
        <Navigate
          to={isLogged && !userInfo?.firstVisit ? "/dashboard/app" : "/login"}
        />
      ),
    },
    {
      path: "change-password",
      element: <ChangePasswordPage />,
    },
    {
      path: "/dashboard",
      element:
        isLogged && !userInfo?.firstVisit ? (
          <DashboardLayout />
        ) : (
          <Navigate to={"/login"} />
        ),
      children: [
        {
          element: <Navigate to="/dashboard/app" />,
          index: true,
        },
        { path: "app", element: <DashboardAppPage /> },
        ...(userRole === "admin" ? adminRoutes : userRoutes),
      ],
    },
    {
      path: "login",
      element:
        isLogged && !userInfo?.firstVisit ? (
          <Navigate to={"/dashboard"} />
        ) : (
          <LoginPage />
        ),
    },
    {
      element: <SimpleLayout />,
      children: [
        {
          element: <Navigate to="/dashboard/app" />,
          index: true,
        },
        { path: "404", element: <Page404 /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    {
      path: "*",
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
