import { createSlice } from "@reduxjs/toolkit";
import {
  createProduct,
  getAllProducts,
  getProductsForBrand,
  editProduct,
  deleteProduct,
  sortProducts,
} from "./productActions";

const initialState = {
  loading: false,
  productInfo: null,
  products: [],
  error: null,
  success: false,
};

const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {},
  extraReducers: {
    [createProduct.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [createProduct.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.productInfo = { _id: payload?._id };
    },
    [createProduct.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [editProduct.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [editProduct.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.productInfo = { _id: payload?._id };
    },
    [editProduct.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [deleteProduct.pending]: (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    },
    [deleteProduct.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.productInfo = { _id: payload?._id };
      state.success = true;
    },
    [deleteProduct.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.success = false;
    },
    [getAllProducts.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getAllProducts.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.products = payload;
    },
    [getAllProducts.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [getProductsForBrand.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getProductsForBrand.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.products = payload;
    },
    [getProductsForBrand.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [sortProducts.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [sortProducts.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.products = payload;
    },
    [sortProducts.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export default productSlice.reducer;
